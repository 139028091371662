.order-list-none-guide-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order-list-none-guide {
    color: #aaa;
}