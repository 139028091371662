.like-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    position: relative;
}

.like-menu-image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
}

.like-menu-name {
    color: #141414;
}

.like-menu-spacer {
    flex: 1;
}

.like-menu-delete-button {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.like-menu-delete-button-text {
    font-size: 12px;
    color: #888;
}

.like-menu-name-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.like-menu-type {
    font-size: 14px;
    color: #999;
}

@media (max-width: 700px) {
    .like-menu-type {
        font-size: 12px;
    }
}