.menu-add-button-container {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-add-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    column-gap: 5px;
}

.menu-add-button-plus-icon {
    width: 20px;
    height: 20px;
}

.menu-add-button-text {
    color: #aaa;
}