.notification {
    position: fixed;
    top: 70px;
    right: calc(50% - 720px + 30px);
    padding: 12px 24px 12px 24px;
    background: rgba(249, 249, 249, 0.9);
    border-radius: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: none;
    border: 1px solid rgba(235,92,46,1);
    box-shadow: 0px 0px 10px #eee;
    z-index: 5;
}

.notification-text {
    color: rgba(235,92,46,1);
}

@media (max-width: 1440px) {
    .notification {
        right: 30px;
    }
}

@media (max-width: 700px) {
    .notification {
        width: 70%;
        right: 50%;
        transform: translateX(50%);
    }
}