.present-guide {
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 10px;
    position: relative;
    background-image: url('../../images/present_guide_background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.present-guide-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.present-guide-title {
    color: #fff;
    margin-left: 50px;
}

.present-guide-contents {
    margin-left: 50px;
    color: #fff;
}

.present-guide-contents.brown {
    color: #f8c77d
}

.present-guide-contents.grey {
    color: #eee;
}

@media (max-width:500px) {
    .present-guide {
        background-position: center;
        border-radius: 10px;
        row-gap: 5px;
    }
    .present-guide-title {
        margin-left: 20px;
    }
    .present-guide-contents {
        margin-left: 20px;
    }
    .present-guide {
        margin-top: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}