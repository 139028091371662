.terms-of-user {
    align-self: center;
    width: 100%;
    max-width: 800px;
    /* padding: 30px 20px 30px 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
}

.terms-of-user-paragraph {
    width: 100%;
}

.terms-of-user-title {
    font-weight: 500;
    color: #141414;
}

.terms-of-user-title.top {
    color: #595959;
}

.terms-of-user-text {
    color: #595959;
    line-height: 28px;
}