.cart-modal-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 20px;    
    min-width: 350px;
}

.cart-modal-row.top {
    justify-content: space-between;
}

.cart-modal-row.middle {
    justify-content: flex-end;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.cart-modal-row.bottom {
    justify-content: flex-end;
    padding-bottom: 40px;
}

.cart-modal-item-name {
    color: #111;
}

.cart-modal-item-price {
    color: #aaa;
}

.cart-modal-option {
    width: 100%;
}

.cart-modal-option-container {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #666;
    background: #fff;
    cursor: pointer;
}

.cart-modal-option-container:focus {
    border: 1px solid #ddd;
    outline: none;
}

.cart-modal-option-container:hover {
    border: 1px solid #ddd;
    outline: none;
}

.cart-modal-option {
    background: #fff;
}

.cart-modal-row-spacer {
    min-height: 5px;
}