.order-history-detail-modal-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 15px;
    overflow-y: auto;
    max-height: 60vh;
    height: 100%;
    padding-bottom: 20px;
    min-width: 400px;
    padding-right: 10px;
}

.order-history-detail-modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.order-history-detail-modal-image {
    width: 100px;
    height: 100px;
}

.order-history-detail-modal-text {
    font-weight: 500;
}

.order-history-detail-modal-text.name {
    color: #141414;
}

.order-history-detail-modal-text.price {
    color: #aaa;
}

.order-history-detail-modal-text-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.order-history-detail-modal-title-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.order-history-detail-modal-title {
    color: #bbb;
}