.social-login-modal-buttons {
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 14px;
}

.social-login-modal-guide {
    color: #666;
}