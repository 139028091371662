.calendar {
    width: 100%;
    max-width: 500px;
}

.calendar-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}

.calendar-month {
    color: #666;
}

.calendar-days-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.6fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1px;
    row-gap: 1px;
}

.calendar-day {
    width: 100%;
    outline: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20%;
    padding-bottom: 40%;
    height: 28px;
}

.calendar-day-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.calendar-month-arrow-button {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background: #ddd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.calendar-month-arrow-button-image {
    width: 100%;
    height: 100%;
}

.calendar-day-button.on {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: rgb(236, 110, 110);
}

.calendar-day-text {
    color: #888;
}

.calendar-day-text.deactivated {
    color: #ccc;
}

.calendar-day-text.on {
    color: #fff;
}

.calendar-year {
    color: #666;
}

.calendar-day-of-week {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #ccc;
}

.calendar-day-of-week-text {
    color: #ccc;
}