.mobile-header {
    display: none;
    padding-right: 20px;
    padding-left: 20px;
}

.mobile-header-spacer {
    flex: 1;
}

@media (max-width: 700px) {
    .mobile-header {
        background: rgba(249, 249, 249, 0.8);
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}