.order-summary {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
}

.order-summary-number {
    color: #999;
}

.order-summary-menus {
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-summary-menus-item {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.order-summary-menus-item-text {
    color: #777;
}