.item {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    row-gap: 5px;
}

.item-image-link {
    width: 200px;
    height: 200px;
}

.item-image {
    width: 100%;
    height: 100%;
    transform: scale(1.00);
    transition: 0.5s;
    border-radius: 10px;
}

.item-image:hover {
    transform: scale(1.05);
    transition: 0.5s;
}

.item-cart-button-container {
    position: absolute;
    top: 156px;
    right: 10px;    
}

@media (max-width: 700px) {
    .item-cart-button-container.widescreen {
        display: none;
    }
    .item {
        max-width: 200px;
        width: 100%;
    }
    .item-image-link {
        width: 100%;
        height: 100%;
        min-height: 150px;
    }
    .item-cart-button-container {
        top: 60%;
        right: 5px;    
    }
}

@media (min-width: 700px) {
    .item-cart-button-container.mobile {
        display: none;
    }
}

.item-text {
    font-weight: 500;
}

.item-text.name {
    color: #141414;
}

.item-text.price {
    color: #767676;
}