@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

* {
	font-family: 'Noto Sans KR', sans-serif;
	background: rgba(0,0,0,0);
  margin: 0;
  padding: 0;
}

html {
	font-family: 'Apple SD Gothic Neo', Roboto, 'Noto Sans KR', NanumGothic, 'Malgun Gothic', sans-serif;
	color: #000;
	background: #fff;
	line-height: 1.2;
	word-wrap: break-word;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  background: rgba(0,0,0,0);
  cursor: pointer;
}

button:focus {
  outline: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

h1 {
  font-size: 28px;
  font-weight: 500;
}

h2 {
  font-size: 24px;
  font-weight: 500;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

span {
  font-size: 16px;
}


@media (max-width: 700px) {
	h1 {
		font-size: 24px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 16px;
	}
	span {
		font-size: 14px;
	}
}