.pay-method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 6px 6px 10px;
}

.pay-method-title {
    color: #777;
}

.pay-method-buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    column-gap: 6px;
}

.pay-method-button {
    padding: 3px 6px 3px 6px;
    border: 1px solid #777;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pay-method-button.on {
    border: 1px solid rgb(235, 92, 46);
    background:rgb(235, 92, 46);
}

.pay-method-button.off {
    border: 1px solid rgb(235, 92, 46);
    background-color: rgba(0,0,0,0);
}

.pay-method-button-text.on {
    color: #fff;
}

.pay-method-button-text.off {
    color: rgb(235, 92, 46);
}
