.order-sheet-contents-container {
    width: 100%;
    max-width: 600px;
}

.order-sheet-contents-container-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.order-sheet-contents-container-row.total-price {
    justify-content: flex-end;
    margin-top: 10px;
}