.home-return-button-container {
    width: 100%;
    max-width: 600px;
}

.home-return-button {
    display: flex;
}

.home-return-button.mobile {
    display: none;
}

@media (max-width: 700px) {
    .home-return-button.mobile {
        display: flex;
        padding-bottom: 20px;
    }

    .home-return-button {
        display: none;
    }
}