.toggle-button {
    display: flex;
    background-color: #eee;
    border-radius: 200px;
    position: relative;
}

.toggle-button-circle {
    background-color: #fff;
    border-radius: 200px;
    position: absolute;
    left: 1px;
    bottom: 1px;
}

.toggle-button-circle.on {
    left: 26px;
    transition: 0.5s;
}

.toggle-button-circle.off {
    left: 1px;
    transition: 0.5s;
}

.toggle-button.on {
    background-color: rgba(235,92,46,1);
}

.toggle-button.off {
    background-color: #eee;
}