.order-list {
    width: 100%;
    max-width: 600px;
}

.order-list-button-container {
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.order-list-button-title {
    color: #aaa;
}

.order-list-canceled-order-exist-guide-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
}

.order-list-canceled-order-exist-guide {
    color: #aaa;
}

