.check-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.check-button-image {
    width: 100%;
    height: 100%;
}