.order-calendar-contents-container-date-guide {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order-calendar-contents-container-date {
    color: #666;
    font-weight: 400;
}

.order-calendar-contents-container-time-guide {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order-calendar-contents-container-time {
    color: #666;
    font-weight: 400;
}

.order-calendar-contents-container-time-selector-container {
    width: 100%;
    max-width: 500px;
    padding-top: 20px;
}