.privacy-policy {
    align-self: center;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
}

.privacy-policy-paragraph {
    width: 100%;
}

.privacy-policy-title {
    font-weight: 500;
    color: #111;
}

.privacy-policy-title.top {
    color: #666;
}

.privacy-policy-text {
    color: #666;
    line-height: 28px;
}