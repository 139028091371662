.order-history-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
}

.order-history-box-image {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.order-history-box-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 5px;
}

.order-history-box-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    column-gap: 10px;
}

.order-history-box-info-title {
    min-width: 100px;
    color: #aaa;
}

.order-history-box-info-value {
    color: #141414;
}

.order-history-box-small-buttons {
    position: absolute;
    bottom: 5px;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.order-history-box-small-button-text {
    color: #888;
    font-size: 12px;
}

@media (max-width: 700px) {
    .order-history-box-info-title {
        min-width: 75px;
    }
    
    .order-history-box {
        padding-bottom: 10px;
    }
}