.modal {
    background: #fff;
    border-radius: 15px;
    border: 1px solid #ECECEC;
    box-shadow: 0px 4px 4px #E2E2E2;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    padding: 15px;
}

.modal::backdrop {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
}

.modal-contents {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.modal-spacer {
    flex: 1;
    min-height: 10px;
}

.modal-close-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-close-button {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    text-decoration-color: #aaa;
    padding: 6px;
}

.modal-close-button-text {
    color: #aaa;
}

@media (max-width: 700px) {
    .modal-spacer {
        min-height: 7px;
    }
}