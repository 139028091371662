.order-state-selector {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.order-state-selector-options {
    flex: 1;
    max-width: 500px;
    padding-left: 20px;
    padding-right: 20px;
    height: 32px;
    background-color: #fff;
    color: #aaa;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.order-state-selector-text {
    color: #aaa;
}