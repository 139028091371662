.item-description {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item-description-image {
    width: 100%;
    height: auto;
}

.item-description-image.image2 {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.item-description-image.image3 {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.item-description-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.item-description-container.name {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
}

.item-description-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-top: 15px;
}

.item-description-title-container.name {
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.item-description-title {
    color: #666;
}

.item-description-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 0px;
    row-gap: 10px;
}

.item-description-text {
    color: #999;
}

.item-description-text.extended-height {
    line-height: 180%;
}

.item-description-gradients-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 7px;
    margin-bottom: 10px;
}

.item-description-text.name {
    color: #666;
    font-weight: 500;
}