.item-class-delete-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item-class-delete-button-text {
    color: #aaa;
    text-decoration: underline;
}