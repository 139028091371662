.order-complete-guide {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.order-complete-guide-image {
    width: 60px;
    height: 60px;
}

.order-complete-guide-header1 {
    color: #666;
    font-weight: 500;
}

.order-complete-guide-header3 {
    color: #666;
    font-weight: 400;
}