.item-contents-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 30px;
    row-gap: 10px;
}

.item-contents-container-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.item-contents-container-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item-contents-container-intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #666;
    font-weight: 400;
}

@media (max-width: 700px) {
    .item-contents-container {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .item-contents-container-row {
        justify-content: flex-start;
    }
    .item-contents-container-title {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}
