.item-class-edit-mobile-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.item-class-edit-mobile-modal-title {
    color: #aaa;
}

.item-class-edit-mobile-modal-input {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 15px;
    text-indent: 10px;
    border-radius: 6px;
    border: 1px solid #eee;
    color: #444;
    outline: none;
}

.item-class-edit-mobile-modal-input:focus {
    outline: 2px solid #EB5C2E;
}

.item-class-edit-mobile-modal-input.disabled {
    color: #777;
}

.item-class-edit-mobile-modal-input.address {
    margin-bottom: 0px;
}