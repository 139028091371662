.mobile-footer {
    display: none;
}

.mobile-footer-cart-add-button-container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    background: #fff;
}

.mobile-footer-nav-container {
    width: 100%;
    height: 60px;
    background: rgba(249, 249, 249, 0.8);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    padding-top: 4px;
}

@media (max-width: 700px) {
    .mobile-footer {
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}