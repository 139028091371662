.page {
    width: 100%;
    height: 100%;
    padding-bottom: 200px;
}

.page.padding-bottom-minimized {
    padding-bottom: 100px;
}

.contents-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.contents {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;    
}

.contents-spacer {
    min-height: 60px;
}

@media (max-width: 700px) {
    .contents-spacer {
        min-height: 10px;
    }
    .contents-spacer.cart {
        min-height: 20px;
    }
    .page.padding-bottom-minimized {
        padding-bottom: 10px;
    }
}