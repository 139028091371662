.simple-order {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #eee;
}

.simple-order-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.simple-order-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 5px;
}

.simple-order-row.flex-end {
    justify-content: flex-end;
}

.simple-order-text {
    color: #666;
}

.simple-order-text.grey {
    color: #c8c8c8;
}

.simple-order-state-selector-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.simple-order-contents {
    width: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;    
    padding-top: 20px;
    padding-bottom: 20px;
    row-gap: 16px; 
}

.simple-order-contents.desktop {
    display: flex;
}

.simple-order-contents.mobile {
    display: none;
}

@media (max-width: 700px) {
    .simple-order-contents.desktop {
        display: none;
    }
    .simple-order-contents.mobile {
        display: flex;
    }
}