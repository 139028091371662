.order-processing-guide {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.order-processing-image {
    width: 60px;
    height: 60px;
    animation: rotate_image 6s linear infinite;transform-origin: 50% 50%;
}

@keyframes rotate_image {
    100% {
        transform: rotate(360deg);
    }
}

.order-processing-guide-header1 {
    color: #6A6A6A;
    font-weight: 500;
}

.order-processing-guide-header3 {
    color: #6A6A6A;
    font-weight: 400;
}