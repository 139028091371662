.social-login-mobile-modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    row-gap: 12px;
}

.social-login-mobile-modal-guide {
    color: #666;
}