.item-options-update-mobile-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.item-options-update-mobile-modal-title {
    color: #aaa;
}

.item-options-update-mobile-modal-input {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 15px;
    text-indent: 10px;
    border-radius: 6px;
    border: 1px solid #eee;
    color: #444;
    outline: none;
}

.item-options-update-mobile-modal-input:focus {
    outline: 2px solid #EB5C2E;
}

.item-options-update-mobile-modal-input.disabled {
    color: #777;
}

.item-options-update-mobile-modal-input.address {
    margin-bottom: 0px;
}

.item-options-update-mobile-modal-add-button-text {
    color: #aaa;    
}

.item-options-update-mobile-modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.item-options-update-mobile-modal-delete-button-icon {
    color: #ccc;
    text-decoration: underline;
}

.item-options-update-mobile-modal-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding-left: 2px;
    padding-right: 2px;
}


.item-options-update-mobile-modal-add-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.item-options-update-mobile-modal-add-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.item-options-update-mobile-modal-add-button-icon {
    width: 20px;
    height: 20px;
}

.item-options-update-mobile-modal-add-button-text {
    color: #aaa;    
}