.store-info {
    border-top: 1px solid #eee;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.store-info-container {
    flex: 1;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
}

.store-info-title {
    color: #6A6A6A;
}

.store-info-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}

.store-info-h3 {
    color: #6A6A6A;
    font-weight: 500;
}

.store-info-social-question-button {
    width: 36px;
    height: 36px;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.store-info-social-question-button-image {
    width: 100%;
    height: 100%;
    border: 0px solid;
}

.store-info-guide-button {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.store-info-guide-button-text {
    color: #6A6A6A;
    font-weight: 600;
}

.store-info-text {
    font-size: 14px;
    color: #6A6A6A;
    font-weight: 500;
}

@media (max-width: 700px) {
    .store-info {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        row-gap: 40px;
    }
    .store-info-text {
        font-size: 10px;
    }
}