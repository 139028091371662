.order-pickup-time-change-mobile-modal-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 15px;
    overflow-y: auto;
    max-height: 80vh;
    height: 100%;
    padding-bottom: 20px;
}

.order-pickup-time-change-mobile-modal-title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.order-pickup-time-change-mobiel-modal-title {
    color: #bbb;
}

.order-pickup-time-change-mobile-modal-row {
    width: 100%;
}