.coupon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 6px;
}

.coupon-title {
    color: #222;
}

.coupon-text {
    color: #888;
}