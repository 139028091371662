.mobile-modal-container {
    z-index: -99;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0);
}

.mobile-modal-container.on {
    display: block;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    z-index: 5;
}

.mobile-modal-container.off {
    /* transition: 10s; */
    z-index: -99;
}

.mobile-modal {
    position: fixed;
    left: 0px;
    bottom: -100vh;
    background: #fff;
    border-radius: 15px;
    border: 1px solid #ECECEC;
    width: 100%;
    min-height: 30vh;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 4;
}

.mobile-modal.on {
    bottom: 0;
    transition: 0.5s;
}

.mobile-modal.off {
    bottom: -100vh;
    /* transition: 1s; */
}

.mobile-modal-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
}

.mobile-modal-children {
    width: 100%;
    flex: 1;
}

.mobile-modal-spacer {
    width: 100%;
    min-height: 10px;
}

.mobile-modal-close-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mobile-modal-complete-button-container {
    width: 100%;
    margin-bottom: 10px;
}

.mobile-modal-close-button {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    text-decoration-color: #aaa;
    padding: 6px;
}

.mobile-modal-close-button-text {
    color: #aaa;
}

@media (min-width: 700px) {
    .mobile-modal-container {
        display: none;
    }
    .mobile-modal-container.on {
        display: none;
    }
    .mobile-modal-container.off {
        display: none;
    }
}