.item-detail {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.item-detail-thumbnail-image {
    width: 250px;
    height: 250px;
    border-radius: 15px;
}

.item-detail-contents-rows {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 10px;
    width: 100%;
}

.item-detail-contents-rows.top {
    row-gap: 5px;
}

.item-detail-contents-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.item-detail-contents-text {
    color: #aaa;
}

.item-detail-contents-row.name {
   justify-content: space-between;
   border-bottom: 1px solid #aaa;
   padding-bottom: 10px;
}

.item-detail-contents-row.purchase-button {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    justify-content: space-between;
}

.item-detail-contents-row.name-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.item-detail-contents-header {
    color: #111;
}

.item-detail-contents-row.price {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.item-detail-contents-row.type {
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
    padding-top: 5px;
    border-bottom: 1px solid #ddd;
}

.item-detail-contents-title {
    color: #aaa;
    min-width: 100px;
}

.item-detail-contents-text.type-detail {
    font-weight: 500;
    color: #666;
}

.item-detail-contents-row.cart {
    justify-content: space-between;
}

.item-detail-contents-row.total-price {
    justify-content: flex-end;
}

.item-detail-contents-row.cart-add-button {
    margin-top: 30px;
    justify-content: flex-end;
}

.item-detail-contents-row.mobile-cart-add-button {
    display: none;
}

.item-detail-contents-like-button {
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
}

.item-detail-contents-like-button-image {
    width: 100%;
    height: 100%;
}

.item-detail-contents-row.delete {
    justify-content: flex-end;
}

.item-detail-contents-row.options-update {
    justify-content: flex-end;
}

@keyframes beat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
}

@media (max-width: 700px) {
    .item-detail {
        flex-direction: column;
        border-bottom: 0px;
    }

    .item-detail-thumbnail-image {
        width: 100%;
        height: auto;
    }

    .item-detail-contents-row.cart-add-button {
        display: none;
    }

    .item-detail-contents-row.mobile-cart-add-button {
        display: flex;
        width: 95%;
        position: fixed;
        bottom: 0px;
        left: 0px;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 30px;
        background: #fff;
        border: none;
        box-shadow: 0px 5px 10px 10px rgba(255,255,255,0.8);
    }
}