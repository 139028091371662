.quadrangle-button {
    border: 1px solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.quadrangle-button-text {
    font-weight: 500;
    color: #fff
}