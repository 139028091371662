.order-sheet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 10px;
}
.order-sheet-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.order-sheet-item-text {
    color: #111;
}

.order-sheet-item-text.right {
    min-width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}