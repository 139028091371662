.manager-order-check-box-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 600px;
    padding-bottom: 10px;
    padding-top: 10px;
    column-gap: 10px;
}

.manager-order-check-box-description {
    color: #C8C8C8;
    font-weight: 500;
    cursor: pointer;
}

.manager-order-check-box-description.off {
    color: #C8C8C8;
}

.manager-order-check-box-description.on {
    color: #EA5B30;
}