.order-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 600px;
}

.order-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
}

.mobile-order-button {
    display: none;
}

@media (max-width: 700px) {
    .order-button.widescreen {
        display: none;
    }

    .mobile-order-button {
        display: flex;
        width: 95%;
        position: fixed;
        bottom: 0px;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 30px;
        background: #fff;
        box-shadow: 0px 5px 10px 10px rgba(255,255,255,0.8);
    }

    .mobile-order-button.fixed-type {
        position: inherit;
    }
}