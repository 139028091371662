.order-pickup-time-change-modal-contents {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;    
}

.order-pickup-time-change-modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.order-pickup-time-change-modal-text {
    font-weight: 500;
}

.order-pickup-time-change-modal-title-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.order-pickup-time-change-modal-title {
    color: #bbb;
}

.order-pickup-time-change-modal-change-button-container {
    width: 100%;
}
