.quadrangle-icon-text-button {
    position: relative;
}

.quadrangle-icon-text-button-icon {
    position: absolute;
}

.quadrangle-icon-text-button:focus {
    outline: 0;
}